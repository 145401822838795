import React from "react";
import Seo from "../components/layout/seo";
import Affilatura from "../components/pages/affilatura";

const data = {
  intro: (
    <p>
      Forniamo un servizio di affilatura per tutti gli utensili, legno e
      alluminio, in HM o diamante.
      <br />
      <br />
      Utilizzate il seguente modulo di contatto per comunicarci tipologia e
      quantità e vi formuleremo un'offerta.
    </p>
  ),
  blocks: [],
};

const ServiziDiAffilatura = ({ location }) => (
  <>
    <Seo
      title="Servizi di affilatura"
      desc="Scopri tutti i Servizi di Affilatura di International Saws. Lunga durata anche in condizioni estreme di utilizzo. Contattaci oggi!"
    />
    <Affilatura data={data} location={location} />
  </>
);

export default ServiziDiAffilatura;
