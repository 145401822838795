import { Link } from "gatsby";
import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/language-context";
import { categories, slugs2name } from "../../resources/categorie";
import t from "../../resources/translations";
import CTA from "../cta";
import BreadCrumb from "../layout/breadcrumb";
import FormFields from "../layout/form-fields";
import Layout from "../layout/layout";
import ComparisonBlok from "../storyblok-components/comparison-blok";
import IntroCard from "../storyblok-components/intro-card";
import ParagraphBlok from "../storyblok-components/paragraph-blok";
/* global dataLayer */

const Affilatura = ({ data, location }) => {
  const l = useContext(LanguageContext).locale;
  const [isSubmitted, setSubmitted] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.sheet = "affilatura";
    try {
      const res = await fetch(
        "https://hooks.zapier.com/hooks/catch/769761/bk2jgke/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    } catch (err) {
      console.log(err);
    }
    dataLayer &&
      dataLayer.push({
        event: "richiesta_contatto",
        ...data,
      });
    setSubmitted(true);
  };

  return (
    <Layout location={location}>
      <BreadCrumb>
        <Link to="/">Home</Link>
        <p className="font-bold">{slugs2name.affilatura[l]}</p>
      </BreadCrumb>
      <h1 className="md:is-h1 is-h1m text-white mb-4">
        {slugs2name.affilatura[l]}
      </h1>
      <IntroCard mobmb="200px">
        <h2 className="is-h3b text-is-blue">{t.intro[l]}</h2>
        <p className="py-6">{data.intro ?? ""}</p>
      </IntroCard>
      <section className="my-4 flex justify-center">
        <div className="p-8 rounded-is bg-is-dark-gray">
          {isSubmitted ? (
            <>
              <div className="mt-2.5">
                <p className="text-lg">{t.testoGrazieContatto[l]}</p>
              </div>
              <div className="mt-6 flex justify-center">
                <button
                  className="is-button"
                  onClick={() => {
                    setSubmitted(false);
                  }}
                >
                  {t.hoCapito[l]}
                </button>
              </div>
            </>
          ) : (
            <form onSubmit={submit}>
              <div className="mt-2.5">
                <p className="text-lg">{t.testoAffilatura[l]}</p>
              </div>
              <div className="mt-6">
                <FormFields />
              </div>
              <div className="mt-6 flex gap-10 items-start">
                <button className="is-button">{t.invia[l]}</button>
                <label className="flex gap-1 items-center text-sm">
                  <input type="checkbox" name="privacy" required />
                  Privacy policy
                </label>
              </div>
            </form>
          )}
        </div>
      </section>
      {(data?.blocks ?? []).map((b) => {
        switch (b.type) {
          case "paragraph":
            return <ParagraphBlok key={b.title} b={b} />;
          case "comparison":
            return <ComparisonBlok key={b.title} b={b} />;
          default:
            return null;
        }
      })}
      <CTA />
    </Layout>
  );
};

export default Affilatura;
